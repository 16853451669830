import styled, { css } from 'styled-components';

export const Stack = styled.div<{ gap?: string }>`
  display: flex;
  flex-direction: column;
  gap: ${(props) => props.gap || css`unset`};
  align-self: stretch;
`;

export const Flex = styled.div<{ justify?: string }>`
  display: flex;
  justify-content: ${(props) => props.justify || css`unset`};
`;

export const CardHeading = styled.h2`
  font-weight: var(--body-text-bold-font-weight);
`;

export const IconContainer = styled.div`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: var(--hover-transition);
  transition-property: background-color, box-shadow, color;
  aspect-ratio: 1;
  height: var(--spacing-4);
  width: var(--spacing-4);
  border-radius: var(--border-radius-round);
  background: transparent;
  color: var(--btn-color);
  box-shadow: inset 0 0 0 1px var(--btn-color);

  &:hover {
    background: var(--btn-color);
    color: var(--btn-text-color);
    box-shadow: inset 0 0 0 1px transparent;
  }
`;

export const StyledButton = styled.button`
  padding: var(--spacing-2);

  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: var(--spacing-2);
  align-self: stretch;
  flex: 1;

  border-radius: var(--border-radius-medium);
  border: 1px solid var(--border-color-dim);
  background: var(--surface-bkg-color);
  cursor: pointer;

  transition: border-color 0.2s ease;
  :hover {
    border-color: transparent;
    box-shadow: 0px 0px 5px 0px var(--highlight-color);
  }

  &:hover ${IconContainer}, &:focus ${IconContainer} {
    background: var(--btn-color);
    color: var(--btn-text-color);
    box-shadow: inset 0 0 0 1px transparent;
  }
`;

export const ButtonHeading = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;

  p {
    color: var(--text-color);
    display: flex;
    align-items: center;
    gap: var(--spacing-1);

    text-align: start;
  }
`;

export const Badges = styled.div`
  display: flex;
  align-items: flex-start;
  gap: var(--spacing-1);
  flex-wrap: wrap;
`;
