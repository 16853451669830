import { api } from 'controllers/network/apiClient';
import {
  ConfigData,
  DebugAdditionalInstructionsResponse,
  Response,
} from './types';

export async function getShopConfigValues(): Promise<ConfigData> {
  return api.get('/v2/shopper/config');
}

export async function setShopConfigValues(
  data: Partial<ConfigData>,
): Promise<Response> {
  return api.post('/v2/shopper/config', data);
}

export async function getDebugAdditionalInstructions(): Promise<DebugAdditionalInstructionsResponse> {
  return api.get('/v2/shopper/debug/additional-instructions');
}

export async function refreshInstructionsData(): Promise<DebugAdditionalInstructionsResponse> {
  return api.post('/v2/shopper/debug/additional-instructions/refresh');
}

export async function activateShopperTrial(): Promise<{ message: string }> {
  return api.post('/v2/shopper/activate');
}
