import { toast } from '@postscript/components';
import { omit } from 'lodash';
import { parse, stringify } from 'query-string';
import { useEffect } from 'react';
import {
  useMutation,
  UseMutationResult,
  useQuery,
  useQueryClient,
  UseQueryResult,
} from 'react-query';
import { useHistory, useLocation } from 'react-router-dom';
import {
  createIntegration,
  CreateIntegrationPayload,
  deleteIntegration,
  DeleteIntegrationPayload,
  getIntegrationByType,
  getOAuthAuthorizeUrl,
  updateIntegration,
  UpdateIntegrationPayload,
} from '../api/integrationsApiClient';
import { Integration, IntegrationMetadata } from '../types/integrationTypes';

export const INTEGRATION_QUERY_KEY = 'integration';

export const useIntegration = (
  integrationType: string,
  options?: any,
): UseQueryResult<Integration | undefined> => {
  return useQuery(
    [INTEGRATION_QUERY_KEY, integrationType],
    () => getIntegrationByType(integrationType),
    {
      staleTime: Infinity,
      ...options,
    },
  );
};

export const useCreateIntegration = (
  integrationType: string,
): UseMutationResult<Integration, any, CreateIntegrationPayload> => {
  const queryClient = useQueryClient();

  return useMutation(
    (integration: CreateIntegrationPayload) => createIntegration(integration),
    {
      onError: (error) => toast.error(error),
      onSettled: () => {
        queryClient.invalidateQueries([INTEGRATION_QUERY_KEY, integrationType]);
      },
    },
  );
};

export const useUpdateIntegration = (
  integrationType: string,
): UseMutationResult<Integration, any, UpdateIntegrationPayload> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (values: UpdateIntegrationPayload) => updateIntegration(values),
    {
      onMutate: async ({ enabled }: UpdateIntegrationPayload) => {
        await queryClient.cancelQueries([
          INTEGRATION_QUERY_KEY,
          integrationType,
        ]);
        queryClient.setQueryData<Integration | undefined>(
          [INTEGRATION_QUERY_KEY, integrationType],
          (old) =>
            old && {
              ...old,
              enabled,
            },
        );
      },
      onError: (error) => toast.error(error),
      onSettled: () => {
        queryClient.invalidateQueries([INTEGRATION_QUERY_KEY, integrationType]);
      },
    },
  );
};

export const useUninstallIntegration = (
  integrationType: string,
): UseMutationResult<void, any, DeleteIntegrationPayload> => {
  const queryClient = useQueryClient();

  return useMutation(
    async (values: DeleteIntegrationPayload) => deleteIntegration(values),
    {
      onError: (error) => toast.error(error),
      onSettled: () => {
        queryClient.invalidateQueries([INTEGRATION_QUERY_KEY, integrationType]);
      },
    },
  );
};

export const useOAuth = (integrationMetadata: IntegrationMetadata) => {
  const { type: integrationType, label: integrationLabel } =
    integrationMetadata;

  const startOAuthFlow = async () => {
    const url = await getOAuthAuthorizeUrl({
      type: integrationType,
    });
    window.open(url, '_self');
  };

  const handleOAuthRedirect = () => {
    const { search } = useLocation();
    const { replace } = useHistory();
    const params = parse(search);
    const isOAuthRedirect = params.oauthRedirect !== undefined;
    const success = params.success === 'True';

    useEffect(() => {
      if (isOAuthRedirect) {
        // Remove the redirect params from the URL so we only display the message once
        const newParams = omit(params, 'oauthRedirect', 'success', 'error');
        replace({ search: stringify(newParams) });

        if (success) {
          toast.success(`Successfully connected to ${integrationLabel}.`);
        } else {
          let errorMessage = `We encountered an error authenticating with ${integrationLabel}.`;
          if (params.error === 'access_denied') {
            errorMessage = 'Access not granted.';
          }
          toast.error(errorMessage);
        }
      }
    }, [isOAuthRedirect]);
  };

  // Handle incoming OAuth redirects automatically
  handleOAuthRedirect();

  return { startOAuthFlow };
};
