export const getShopperTrialStartedKey = (shopId: number) =>
  `shop-${shopId}-shopper-trial-started`;

export const getShopperSelfServePromoBannerDismissedKey = (shopId: number) =>
  `shop-${shopId}-shopper-self-serve-promo-banner-dismissed`;

export const getShopperIsHereBannerDismissedKey = (shopId: number) =>
  `shop-${shopId}-shopper-is-here-banner-dismissed`;

export const getActivateShopperBannerDismissedKey = (shopId: number) =>
  `shop-${shopId}-activate-shopper-banner-dismissed`;

export const getDemoShopperModalShownKey = (shopId: number) =>
  `shop-${shopId}-demo-shopper-modal-shown`;
