import useAccountStatus from 'components/account/useAccountStatus';
import { useHasProductAddOn } from 'components/billing/modules/plans/hooks/useHasProductAddOn';
import { useProductTrialStatus } from 'components/billing/modules/plans/hooks/useProductTrialStatus';
import { GET_SHOPPER_CONFIG_QUERY_KEY } from 'components/shopper/constants';
import { getShopConfigValues } from 'components/shopper/shopperClient';
import { ConfigData } from 'components/shopper/types';
import { getShopperTrialStartedKey } from 'components/shopper/utils/helpers';
import { useUser } from 'controllers/contexts/user';
import { useLocalStorageValue } from 'hooks/useLocalStorageValue';
import { useShopperStatus } from 'hooks/useShopperStatus';
import { useQuery } from 'react-query';

/**
 * Provide information on the billing status of Shopper.
 */
export const useShopperBillingStatus = () => {
  const { user } = useUser();
  const { isEligibleForShopperSelfServe } = useShopperStatus();
  const { shopperVerificationStatus } = useAccountStatus();
  const hasProduct = useHasProductAddOn('SHOPPER', 'SHOPPER_PLATFORM');
  const { onProductFreeTrial, hasExpiredProductFreeTrial } =
    useProductTrialStatus('SHOPPER_PLATFORM');
  const hasShopperTrialStartedInLocalStorage = useLocalStorageValue<boolean>(
    getShopperTrialStartedKey(user.shop_id),
  );
  const { data: configData } = useQuery<ConfigData>({
    queryKey: GET_SHOPPER_CONFIG_QUERY_KEY,
    queryFn: getShopConfigValues,
  });

  const isEligible =
    isEligibleForShopperSelfServe && shopperVerificationStatus === 'ACTIVE';

  const trialAvailable =
    isEligible &&
    !hasProduct &&
    !hasExpiredProductFreeTrial &&
    !hasShopperTrialStartedInLocalStorage;

  return {
    /** Is eligible for a Shopper plan, and has not been manually denied compliance status */
    isEligible,

    /** Has a Shopper plan */
    hasProduct,

    /** Is eligible for a free trial of Shopper. Mutually exclusive with hasProduct. */
    trialAvailable,

    /** Is currently on a trial of Shopper */
    trialOngoing: onProductFreeTrial,

    /** Has an expired trial of Shopper */
    trialExpired: hasExpiredProductFreeTrial,

    /** Has a Shopper trial that has started by setting a local storage value but not yet activated */
    shopperTrialStartedButNeverActivated:
      trialAvailable &&
      hasShopperTrialStartedInLocalStorage &&
      !configData?.metadata?.last_go_live_at,

    /** Shopper has been activated in the past but is currently deactivated */
    shopperPreviouslyActivatedCurrentlyDeactivated:
      !configData?.is_live_mode &&
      configData?.metadata?.last_go_live_at !== null,
  };
};
