import { useCallback, useEffect, useState } from 'react';

/**
 * A hook that always returns the latest value from localStorage.
 * This is useful when you need to ensure you have the most up-to-date value,
 * especially when the value might be changed from other tabs or windows.
 * Returns null if the key doesn't exist in localStorage.
 */
export const useLocalStorageValue = <T>(key: string): T | null => {
  const getValue = useCallback(() => {
    if (typeof window === 'undefined') {
      return null;
    }
    try {
      const item = window.localStorage.getItem(key);
      return item ? JSON.parse(item) : null;
    } catch (error) {
      console.warn(`Error reading localStorage key "${key}":`, error);
      return null;
    }
  }, [key]);

  // State to store the current value
  const [value, setValue] = useState<T | null>(getValue);

  // Update the value whenever localStorage changes
  useEffect(() => {
    const handleStorageChange = () => {
      setValue(getValue());
    };

    // Listen for storage events from other tabs/windows
    window.addEventListener('storage', handleStorageChange);

    // Also listen for changes in the current tab
    const interval = setInterval(handleStorageChange, 100);

    return () => {
      window.removeEventListener('storage', handleStorageChange);
      clearInterval(interval);
    };
  }, [getValue]);

  return value;
};
