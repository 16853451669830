/* eslint-disable camelcase */
import { api } from 'controllers/network/apiClient';
import moment from 'moment';
import React, { createContext } from 'react';
import { useQuery } from 'react-query';

const SHOPPER_STATUS_ENDPOINT = '/v2/shopper/status';

export interface ShopperStatusResponse {
  is_brand_center_ready: boolean;
  is_eligible_for_shopper_self_serve: boolean;
  is_shopper_beta_user: boolean;
  has_shopper_feature_enabled: boolean;
  has_active_billing_plan: boolean;
  is_live: boolean;
  trial_ends_at: string | null;
}

const fetchShopperStatus = async (): Promise<ShopperStatusResponse> => {
  try {
    const response = await api.get<ShopperStatusResponse>(
      SHOPPER_STATUS_ENDPOINT,
    );
    return response;
  } catch (error) {
    console.error('Error fetching Shopper status:', error);
    // Fallback on:
    return {
      is_brand_center_ready: false,
      is_eligible_for_shopper_self_serve: false,
      is_shopper_beta_user: false,
      has_shopper_feature_enabled: false,
      has_active_billing_plan: false,
      is_live: false,
      trial_ends_at: null,
    };
  }
};

/**
 * Shopper Status Context
 * ----------------------
 * This context is the source of truth for checking various shopper access state checks. It is
 * distinct from `useShopperBillingStatus`, which aims to answer questions about Shopper billing state.
 */
export interface ShopperStatusContextType {
  /**
   * `true` if Brand Center is fully configured for the brand;
   * this is a pre-requisite to Shopper knowing how to speak in the shop's voice and tone.
   * `null` before data is loaded.
   */
  isBrandCenterReady: boolean | null;

  /**
   * Indicates if the brand is eligible to onboard via the Shopper Self-Serve flow.
   * Eligibility is manually toggled after identification by the sales team.
   * `null` before data is loaded.
   */
  isEligibleForShopperSelfServe: boolean | null;

  /**
   * Whether the shopper product is currently active (true for trial or paid plans).
   * `null` before data is loaded.
   */
  hasShopperFeatureEnabled: boolean | null;

  /**
   * Reflects if the Shopper agent is live (sending messages).
   * Toggled on/off via the Shopper config form.
   * `null` before data is loaded.
   */
  isLive: boolean | null;

  /**
   * JavaScript `Date` representing when the trial ends.
   * `null` if the shop has never trialled.
   * Unix Epoch (`new Date(0)`) if trial was cancelled.
   */
  trialEndsAt: Date | null;

  /**
   * True while the shopper status API is being fetched.
   */
  isLoading: boolean;

  /**
   * True if the API call failed.
   */
  isError: boolean;

  // -------------------------
  // Computed Values
  // -------------------------

  /**
   * Number of days remaining in trial (rounded up).
   * `0` if trial has ended or never existed.
   */
  trialDaysRemaining: number;

  /**
   * True if the current date is after `trialEndsAt`.
   * False if still in trial or never had one.
   */
  hasExpiredTrial: boolean;

  /**
   * Indicates whether the trial is available:
   * true if the shop is eligible, hasn't enabled the feature, and trial is still valid.
   */
  trialAvailable: boolean;
}

export const ShopperStatusContext = createContext<
  ShopperStatusContextType | undefined
>(undefined);

export const SHOPPER_STATUS_QUERY_KEY = 'shopper-status';

const getTrialDaysRemaining = (trialEndsAt?: string | null) => {
  if (trialEndsAt === null || trialEndsAt === undefined) {
    return 0;
  }

  /* +1 is to round up */
  const daysRemaining = moment(trialEndsAt).diff(moment(), 'days') + 1;

  return Math.max(daysRemaining, 0);
};

const getHasTrialExpired = (trialEndsAt?: string | null) => {
  /* null trialEndsAt indicates user never had trial */
  if (!trialEndsAt) {
    return false;
  }

  return moment.utc().isAfter(moment.utc(trialEndsAt).endOf('day'));
};

export const ShopperStatusProvider: React.FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const { data, isLoading, isError } = useQuery(
    [SHOPPER_STATUS_QUERY_KEY],
    fetchShopperStatus,
    {
      staleTime: 5 * 60 * 1000,
      cacheTime: 10 * 60 * 1000,
    },
  );

  const trialDaysRemaining = getTrialDaysRemaining(data?.trial_ends_at);
  const hasExpiredTrial = getHasTrialExpired(data?.trial_ends_at);

  const isEligibleForShopperSelfServe =
    data?.is_eligible_for_shopper_self_serve ?? null;
  const hasShopperFeatureEnabled = data?.has_shopper_feature_enabled ?? null;

  return (
    <ShopperStatusContext.Provider
      value={{
        isBrandCenterReady: data?.is_brand_center_ready ?? null,
        isEligibleForShopperSelfServe,
        hasShopperFeatureEnabled,
        isLive: data?.is_live ?? null,
        trialEndsAt:
          (data?.trial_ends_at && new Date(data.trial_ends_at)) || null,
        isLoading,
        isError,

        // Computed Values
        trialDaysRemaining,
        hasExpiredTrial,
        trialAvailable:
          !!isEligibleForShopperSelfServe &&
          !hasShopperFeatureEnabled &&
          !hasExpiredTrial,
      }}
    >
      {children}
    </ShopperStatusContext.Provider>
  );
};
