import { api } from 'controllers/network/apiClient';
import { Integration } from '../types/integrationTypes';

export interface CreateIntegrationPayload {
  type: string;
  enabled: boolean;
  apiKey?: string;
  data?: Record<string, any>;
}

export interface UpdateIntegrationPayload {
  id: number;
  type: string;
  enabled: boolean;
  apiKey?: string;
  data?: Record<string, any>;
}

export interface DeleteIntegrationPayload {
  id: number;
  type: string;
}

export interface GetOAuthAuthorizeUrlPayload {
  type: string;
  data?: Record<string, any>;
}

export async function getIntegrationByType(
  type: string,
): Promise<Integration | undefined> {
  const response = await api.get(`/v2/integrations?type__eq=${type}`);
  if ((response?.integrations || []).length === 1) {
    return response.integrations[0];
  }
  return undefined;
}

export async function createIntegration({
  type,
  enabled,
  apiKey,
  data,
}: CreateIntegrationPayload): Promise<Integration> {
  return api.post(`/v2/integrations/${type}`, {
    enabled,
    api_key: apiKey,
    data,
  });
}

export async function updateIntegration({
  id,
  type,
  enabled,
  apiKey,
  data,
}: UpdateIntegrationPayload): Promise<Integration> {
  return api.put(`/v2/integrations/${type}/${id}`, {
    enabled,
    api_key: apiKey,
    data,
  });
}

export async function deleteIntegration({
  id,
  type,
}: DeleteIntegrationPayload): Promise<void> {
  await api.delete(`/v2/integrations/${type}/${id}`);
}

export async function getOAuthAuthorizeUrl({
  type,
  data,
}: GetOAuthAuthorizeUrlPayload): Promise<string> {
  const response = await api.post(`/v2/integrations/${type}/oauth/init`, {
    ...data,
  });
  return response.url;
}
