/* eslint-disable camelcase */

import { SHOPPER_CONFIG_BASE_PATH } from 'components/shopper/constants';
import { BRAND_CENTER_PATH } from './constants';
import { PRODUCT_CATALOG_PATH } from './product/constants';
import { BrandingLinkCategory } from './sources/constants';

export type RangeAttributeBoundary = [number, number];

export interface BrandingStatus {
  ready: boolean;
}

export interface BrandingRangeAttribute {
  inputName: string;
  range: RangeAttributeBoundary;
  value: number;
}

export interface BrandingTextAttribute {
  inputName: string;
  value: string;
}

export interface BrandingMultiTextAttribute {
  inputName: string;
  value: string[];
}

export type BrandingValue =
  | BrandingRangeAttribute['value']
  | BrandingTextAttribute['value']
  | BrandingMultiTextAttribute['value'];

export type BrandingValues = Record<string, BrandingValue>;

export interface BrandingAttribute {
  inputName: string;
  range?: [number, number];
  value: BrandingValue;
}

export type BrandingType = 'marketing' | 'ai_generated' | 'file_ai_generated';

export interface Branding {
  shopId: number;
  guid: string;
  attributes: BrandingAttribute[];
  type: BrandingType;
}

export interface BrandingPayload {
  rangeAttributes: BrandingRangeAttribute[];
  textAttributes: BrandingTextAttribute[];
  multiTextAttributes: BrandingMultiTextAttribute[];
}

export type RangeAttributeBoundaries = Map<string, RangeAttributeBoundary>;

export interface BrandingDefaultValue {
  [key: string]: BrandingValue;
}

export type BrandingDefaultValues = BrandingDefaultValue[];

export interface GeneratedMessage {
  generatedMessage: string;
  originalMessage: string;
  explanation: string;
  trigger: string;
  allowedTags: string[];
  playbooks: Record<string, string>;
}

export interface LogFormChangesProps {
  initialValues: BrandingValues;
  updatedValues: BrandingValues;
  shopId: number;
}

type BrandingSourceOrigin = 'SCRAPED' | 'USER_PROVIDED' | 'ADMIN_PROVIDED';

export interface BrandingFileSource {
  id: number;
  shopId: number;
  name: string;
  objectUrl: string;
  objectKey: string;
  bucketName: string;
  category: string;
  origin: BrandingSourceOrigin;
  active: boolean;
  createdAt: string;
  updatedAt: string;
  deletedAt: string | null;
}

export enum BrandingLinkSourceError {
  BAD_HTML = 'NO_HTML',
  PERMISSION_DENIED = 'PERMISSION_DENIED',
  PAGE_NOT_FOUND = 'PAGE_NOT_FOUND',
  UNKNOWN_HTTP_ERROR = 'UNKNOWN_HTTP_ERROR',
  LLM_ERROR = 'LLM_ERROR',
  UNKNOWN_ERROR = 'UNKNOWN_ERROR',
}

export interface BrandingLinkSource {
  id: number;
  shopId: number;
  origin: BrandingSourceOrigin;
  errorCode: BrandingLinkSourceError | null;
  nonceId: string | null;
  deletedAt: string | null;
  createdAt: string;
  updatedAt: string;
  url: string;
  category: BrandingLinkCategory;
  active: boolean;
}

export interface AddLinkSourceParams {
  url: string;
  category: string;
  active: boolean;
  nonceId: string | null;
}

export interface UpdateLinkSourceParams extends AddLinkSourceParams {
  id: number;
}

export interface UpdateFileSourceParams {
  id: number;
  name: string;
  category: string;
  active: boolean;
}

export interface PersistRangeBoundaryParams {
  name: string;
  min: number;
  max: number;
}

type Category = 'automations' | 'campaigns';

export interface InspirationMessages {
  [key: string]: string[];
}

export interface InspirationExamples {
  category: Category;
  event_type: string;
  selection_types: InspirationMessages[];
}

export interface InspirationPayload {
  inspiration_message: string;
  preview_type: string;
}

export const BrandCenterRoutes = {
  VOICE: BRAND_CENTER_PATH,
  SHOPPER: SHOPPER_CONFIG_BASE_PATH,
  PRODUCTS: PRODUCT_CATALOG_PATH,
} as const;
