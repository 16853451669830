import { LinkText } from '@postscript/components';
import { BrandingLinkSourceError } from '../types';

export enum BrandingLinkCategory {
  SOCIAL_MEDIA = 'social media',
  PRODUCT_CATALOG = 'product catalog',
  BRANDING = 'branding',
  POLICIES = 'policies',
  FAQ = 'faq',
  SUPPORT = 'support',
  GUIDES_QUIZZES = 'guides & quizzes',
  SALES_EVENTS = 'sales events',
  OTHER = 'other',
}

export const BRANDING_LINK_SOURCE_ERROR_MESSAGES: Record<
  BrandingLinkSourceError,
  JSX.Element
> = {
  [BrandingLinkSourceError.BAD_HTML]: (
    <>We couldn&apos;t find valid content on the page.</>
  ),
  [BrandingLinkSourceError.PERMISSION_DENIED]: (
    <>
      Postscript can&apos;t access this content. Try adding it to its own store
      page.{' '}
      <LinkText
        style={{ color: 'var(--gray-7)' }}
        href="https://help.postscript.io/hc/en-us/articles/35815875619739-Create-a-Collapsible-FAQ-Page-in-Shopify"
        rel="noopener noreferrer"
        target="_blank"
      >
        Learn More
      </LinkText>
    </>
  ),
  [BrandingLinkSourceError.PAGE_NOT_FOUND]: (
    <>This page couldn&apos;t be found (404).</>
  ),
  [BrandingLinkSourceError.UNKNOWN_HTTP_ERROR]: (
    <>Something went wrong when trying to access the page.</>
  ),
  [BrandingLinkSourceError.LLM_ERROR]: (
    <>An error occurred while generating FAQ content.</>
  ),
  [BrandingLinkSourceError.UNKNOWN_ERROR]: <>An unknown error occurred.</>,
};

export const SOURCE_CATEGORIES = [
  { label: 'Social media', value: 'social media' },
  { label: 'Product catalog', value: 'product catalog' },
  { label: 'Branding', value: 'branding' },
  { label: 'Policies', value: 'policies' },
  { label: 'FAQ', value: 'faq' },
  { label: 'Support', value: 'support' },
  { label: 'Guides & Quizzes', value: 'guides & quizzes' },
  { label: 'Sales events', value: 'sales events' },
  { label: 'Other', value: 'other' },
];

export const brandingStatusKey = 'bcc_has_shop_branding';
export const brandingKey = 'bcc_shop_branding';
export const previewMessagesKey = 'bcc_preview_messages';
export const inpirationMessagesKey = 'bcc_im_messages';
export const fileSourcesKey = 'bcc_file_sources';
export const linkSourcesKey = 'bcc_link_sources';
export const brandingLinkSource = 'bcc_link_source';
export const initAIGeneratedBrandingKey = 'bcc_init_ai_generated_branding';
