import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { BANNER_TYPES } from 'utils/events';
import { usePSLabs } from '../../controllers/contexts/labsFeatures';
import { KLAVIYO_OAUTH } from '../admin/utils/feature-flags';
import { useBanners } from '../GlobalBanners/globalBanners';
import { useIntegration } from './hooks/useIntegration';

const BANNER_ID = 'KLAVIYO_OAUTH_MIGRATION_BANNER';

const KlaviyoOAuthMigrationBanner = () => {
  const { addBanner, removeBanner } = useBanners();
  const history = useHistory();
  const { hasLabsFlag, hasInitialized } = usePSLabs();
  const { data: integration } = useIntegration('Klaviyo');

  const oauthEnabled = hasInitialized && hasLabsFlag(KLAVIYO_OAUTH);
  const oauthMigrationAvailable =
    oauthEnabled &&
    integration !== undefined &&
    !integration.data?.oauth_refresh_token;

  useEffect(() => {
    if (oauthMigrationAvailable) {
      addBanner({
        id: BANNER_ID,
        isDismissable: true,
        shouldPersistDismiss: true,
        data: {
          variant: 'default',
          heading: "We've upgraded our Klaviyo integration",
          bodyText: 'Reauthenticate for improved security and added features.',
          primaryAction: {
            onClick: () => {
              removeBanner(BANNER_ID);
              history.push('/integrations/klaviyo?migrateToOAuth=true');
            },
            text: 'Reauthenticate',
          },
        },
        eventTrackingOptions: {
          bannerType: BANNER_TYPES.KLAVIYO_OAUTH_MIGRATION,
        },
      });
    }
  }, [oauthMigrationAvailable]);

  return null;
};

export default KlaviyoOAuthMigrationBanner;
